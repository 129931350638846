<template>
  <div>
    <!-- 面包屑区域  fullscreen-->
    <div class="ipSubmit_home" :style="{width:widthStyle[2]}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>IP信息</el-breadcrumb-item>
        <el-breadcrumb-item>IP信息提交</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 查看详情弹窗 -->
    <el-dialog title="错误提示" :visible.sync="open" :width="dialogWidth">
      <div>{{ msg }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="open = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情弹窗结束 -->

    <!-- 弹窗 -->
    <el-dialog title="温馨提示" :visible.sync="sync" :width="dialogWidth">
      <div class="flex_Dad">
        <div>
          <h1>1.单IP上传</h1>
          <p class="test">当你需要上传单IP时请按照图片所指示，请在左侧红内框中填入IP</p>
          <div>
            <img class="flex_Dad_img" src="../../public/images/ip.png" alt />
          </div>
        </div>
        <div>
          <h1>2.多IP上传</h1>
          <p class="test">当你需要上传IP段时请按照图片所指示，请在左侧红内框中填入IP段</p>
          <div>
            <img class="flex_Dad_img" src="../../public/images/multi_ip.png" alt />
          </div>
        </div>
      </div>
      <div class="test">
        注：单IP和多IP可以存在同一个Excel文件中，表格第一行请按照图示, 可以在线
        <el-link type="primary" :href="BaseUrl1">下载模板</el-link>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sync_ip">我知道了</el-button>
      </span>
    </el-dialog>

    <el-dialog title="上传表格" :visible.sync="drawer" :width="dialogWidth" :open="open_dialog" :close-on-click-modal="false" :before-close="close_dialog">
      <div class="upload_excel">
        <el-upload class="upload-demo" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          drag :on-success="handleAvatarSuccess" :action="BaseUrl2" :headers="headers" multiple
          :auto-upload="false" ref="upload_xls" :data="q_info">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传excil文件，文件只能是XLS/XLSX</div>
        </el-upload>
        <el-button class="upload_submit" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <el-table :data="info" style="width: 100%" border>
          <el-table-column prop="path" label="文件" align="center"></el-table-column>
          <el-table-column prop="updatedAt" label="提交时间" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.updatedAt | timer }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 0">
                <el-button type="danger" size="mini" icon="el-icon-warning" @click="open_click(scope.row.status_msg)">查看详情</el-button>
              </div>
              <div v-else-if="scope.row.status == 1">
                <el-button type="info" size="mini" icon="el-icon-loading">正在处理</el-button>
              </div>
              <div v-else>
                <el-button type="success" size="mini" icon="el-icon-check">成功</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 弹窗结束 -->
    <!-- 卡片区域 -->
    <div class="card">
      <el-card shadow="always">
        <!-- 搜索框部分 -->
        <div class="card_top">
          <div class="search_ip">
            <el-input placeholder="请输入内容" v-model="input_ip" clearable @clear="del_value()" @keyup.enter.native="search()"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          </div>
          <div class="add_ip">
            <el-button type="primary" @click="dialogVisible = true">上传IP</el-button>
            <el-button type="success" plain style="width: 120px" @click="sync = true" v-if="type != 1">上传exeil文件</el-button>
          </div>
          <div class="add_ip">
            <el-button type="danger" plain style="width: 120px" @click="toggleSelection">批量删除</el-button>
          </div>
        </div>
        <!-- 弹窗部分 -->
        <el-dialog title="上传IP" :visible.sync="dialogVisible" :width="dialogWidth">
          <!-- 下拉选择框 -->
          <div class="select">
            <!-- 选择按钮 -->
            <div class="input_ip">
              <span style="margin-right: 8px">&nbsp;IP&nbsp;类型：</span>
              <div class="el-input">
                <div class="flex">
                  <el-radio v-model="value" label="1">单IP</el-radio>
                  <el-radio v-model="value" label="2">多IP</el-radio>
                </div>
              </div>
            </div>
            <!-- 是代理还是自用 -->
            <div class="input_ip">
              <span>&nbsp;IP&nbsp;代理：</span>
              <div class="el-input">
                <div class="flex">
                  <el-radio-group v-model="info_value" v-for="(p, i) of info_usage" :key="i">
                    <el-radio :label="p.id">{{ p.name }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="radios_input_ip">
              <div class="input_ip" v-if="value == '1'">
                <span>单IP输入：</span>
                <el-input v-model="single_ip" placeholder="请输入IP"></el-input>
              </div>
              <div class="input_ips" v-else>
                <span>IP段输入：</span>
                <!-- 填IP -->
                <el-input v-model="paragraph_ip1" placeholder="请输入IP"></el-input>
                <span>-</span>
                <el-input v-model="paragraph_ip2" placeholder="请输入IP"></el-input>
              </div>
            </div>
            <div v-if="type == 1">
              <div class="input_ip">
                <span>企业名称：</span>
                <el-input  placeholder="请输入企业名称" v-model="submiter"></el-input>
              </div>
              <div class="input_ip">
                <span>企业地址：</span>
                <el-input  placeholder="请输入企业地址" v-model="address"></el-input>
              </div>
            </div>
          </div>

          <span slot="footer" style="justify-content:space-evenly"  class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="add_ips">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 表格区域 -->
        <div class="search_table">
          <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading" element-loading-text="拼命加载中">
            <el-table-column prop="id" label="ID" type="selection"></el-table-column>
            <el-table-column prop="IP_type" label="IP类型"></el-table-column>
            <el-table-column prop="ip" label="IP信息">
              <template slot-scope="scope">
                <div v-if="scope.row.ip == scope.row.multi_Ip">{{ scope.row.ip }}</div>
                <div v-else>{{ scope.row.ip }} --- {{ scope.row.multi_Ip }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="creatTime" label="日期"></el-table-column>
            <el-table-column prop="usage_id" label="类型">
              <template slot-scope="scope">
                <div>
                  <el-tag type="success">
                    {{ scope.row.usage_type }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="操作">
              <template slot-scope="scope">
                <div class="solt_button">
                  <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="delete_ip(scope.row.id)"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页部分 -->
        <div class="pagination">
          <Pagenation :size="30" :total="total" v-on:getPage="handleCurrentChange" />
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  //引入加密函数
  import { encryption} from '../utils/phpapi.js'
  import unixTimeToDateTime from '../filter/data'
  import { Base64 } from 'js-base64';
  var test_ip = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/

  import Pagenation from '../components/pagenation'
  export default {
    components: {
      Pagenation
    },
    data() {
      return {
		//上传模板
		BaseUrl1:this.$store.state.BaseUrl+"admin/download",
		// 上传表格
		BaseUrl2:this.$store.state.BaseUrl+"admin/ip_file",
        //默认选择框的值
        value: '1',
        //搜索的ip
        input_ip: '',
        dialogVisible: false,
        // 登录用户的类型
        type: '',
        //ip上传的单IP
        single_ip: '',
        // 企业地址
        address: '',
        // 抽屉开关
        drawer: false,
        // 请输入企业名称
        submiter: '',
        // 详情弹窗
        open: false,
        // 弹窗
        sync: false,
        // 多IP
        paragraph_ip1: '',
        paragraph_ip2: '',
        // 页数
        pagenumber: 1,
        // 代理类型
        info_value: 1,
        // 倒计时请求数据
        info: [],
        //数据表格
        tableData: [],
        // 错误
        msg: '',
        //   选择框的选择

        // 所有页数
        total: 0,
        // 定时器
        settimer: '',
        // 代理类型
        info_usage: [],
        // 数据
        select_list: [],
        // 弹窗是否为全屏
        fullscreen: true,
        //   选择框的选择
        loading: true,
        dialogWidth: '',
        user_id: '',
        //企业的地址与名称
        q_info: {}
      }
    },
    computed: {
      headers() {
        return {
          'Authorization': encryption(window.localStorage.getItem('token') + this.user_id)
        }
      },
      widthStyle() {
        return this.$store.state.widthStyle
      }
    },

    // 拿到用户登录的身份
    mounted() {
      this.user_id = JSON.parse(window.sessionStorage.getItem('info')).user_id
      // 拿到代理类型
      this.get_ip_usage()
      // 拿到数据
      this.get_ip_list()
      let users_type = JSON.parse(window.sessionStorage.getItem('info')).type
      this.type = users_type

      //窗口变化时调用
      window.onresize = () => {
        return (() => {
          this.setDialogWidth()
        })()
      }
    },

    methods: {
      //根据页面宽度设置弹出窗口的宽度
      setDialogWidth() {
        var val = document.body.clientWidth
        const def = '55%' // 默认宽度
        if (val < 550) {
          this.dialogWidth = '80%'
        } else {
          this.dialogWidth = def
        }
      },
      // 清空事件
      del_value() {
        this.get_ip_list()
      },
      // 数据按钮
      handleSelectionChange(val) {
        this.select_list = []
        val.forEach(row => {
          this.select_list.push(row.id)
        })
      },
      // 删除按钮
      toggleSelection() {
        if (this.select_list.length != 0) {
          this.$confirm('此操作将永久删除该IP, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$axios
              .post('del_ip_batch', {
                id: this.select_list.join(',')
              })
              .then(res => {
                if (res.code == 201) {
                  this.get_ip_list()
                }
              })
          })
        } else {
          this.$message('请选择您要删除的IP')
        }
      },
      // 拿到代理类型
      get_ip_usage() {
        this.$axios('/get_ip_usage').then(res => {
          this.info_usage = res.data
        })
      },
      // 窗口打开
      open_dialog() { },
      // 分页部分
      handleCurrentChange(val) {
        this.pagenumber = val
        this.get_ip_list()
      },
      // 窗口关闭
      close_dialog() {
        this.drawer = false
        this.get_ip_list()
      },
      // 拿到表格数据
      get_ip_list() {
        this.$axios.post('/home_ip_submits', {
          id: this.user_id,
          pagenumber: this.pagenumber,
          ip: ''
        }).then(res => {
          this.loading = false
          res.data.forEach(item => {
            item.creatTime = unixTimeToDateTime(item.creatTime)
            if (item.IP_type == 1) {
              item.IP_type = '单IP'
            } else {
              item.IP_type = 'IP段'
            }
          })
          this.tableData = res.data
          this.total = res.count
        })
      },
      // 添加IP
      add_ips() {
        // // 单IP提交
        if (this.value == '1') {
          // 判断单IP是不是为空
          if (this.single_ip == '') {
            this.$message({
              type: 'error',
              message: 'IP不能为空'
            })
            // 判断是警方还是企业
          } else if (!test_ip.test(this.single_ip)) {
            this.$message({
              type: 'error',
              message: 'IP格式错误'
            })
          } else if (this.type == 1) {
            // 是警方判断地址跟名称是不是为空
            if (this.address == '') {
              this.$message({
                type: 'error',
                message: '企业地址不能为空'
              })
            } else if (this.submiter == '') {
              this.$message({
                type: 'error',
                message: '企业名称不能为空'
              })
            } else {
              this.$axios
                .post('/Ip_submit', {
                  ip: this.single_ip,
                  multi_ip: this.single_ip,
                  address: Base64.encode(this.address),
                  submitter: Base64.encode(this.submiter),
                  ip_type: 1,
                  usage_id: this.info_value,
                  user_id: this.user_id
                })
                .then(res => {
                  if (res.code == 201) {
                    this.get_ip_list()

                    this.dialogVisible = false
                  }
                })
            }
          } else {
            // 企业提交从缓存中获取信息
            this.address = JSON.parse(window.sessionStorage.getItem('info1')).address
            this.submiter = JSON.parse(window.sessionStorage.getItem('info1')).enterpriseName
            this.$axios
              .post('/ip_submit', {
                ip: this.single_ip,
                multi_ip: this.single_ip,
                address: Base64.encode(this.address),
                submitter: Base64.encode(this.submiter),
                ip_type: 1,
                usage_id: this.info_value,
                user_id: this.user_id
              })
              .then(res => {
                if (res.code == 201) {
                  this.dialogVisible = false
                  this.get_ip_list()
                }
              })
          }
        } else {
          // 多IP提交 判断是不是为空
          if (this.paragraph_ip1 == '' || this.paragraph_ip2 == '') {
            this.$message({
              type: 'error',
              message: 'IP不能为空'
            })
          } else if (!test_ip.test(this.paragraph_ip1) || !test_ip.test(this.paragraph_ip2)) {
            this.$message({
              type: 'error',
              message: 'IP格式错误'
            })
          } else if (this.type == 1) {
            // 是警方判断地址跟名称是不是为空
            if (this.address == '') {
              this.$message({
                type: 'error',
                message: '企业地址不能为空'
              })
            } else if (this.submiter == '') {
              this.$message({
                type: 'error',
                message: '企业名称不能为空'
              })
            } else {
              this.$axios
                .post('/ip_submit', {
                  ip: this.paragraph_ip1,
                  multi_ip: this.paragraph_ip2,
                  address: Base64.encode(this.address),
                  submitter: Base64.encode(this.submiter),
                  ip_type: '0',
                  usage_id: this.info_value,
                  user_id: this.user_id
                })
                .then(res => {
                  if (res.code == 201) {
                    this.get_ip_list()
                    this.dialogVisible = false
                  }
                })
            }
          } else {
            this.address = JSON.parse(window.sessionStorage.getItem('info1')).address
            this.submiter = JSON.parse(window.sessionStorage.getItem('info1')).enterpriseName
            this.$axios
              .post('/ip_submit', {
                ip: this.paragraph_ip1,
                multi_ip: this.paragraph_ip2,
                address: Base64.encode(this.address),
                submitter: Base64.encode(this.submiter),
                ip_type: '0',
                usage_id: this.info_value,
                user_id: this.user_id
              })
              .then(res => {
                if (res.code == 201) {
                  this.get_ip_list()
                  this.dialogVisible = false
                }
              })
          }
        }
      },
      // 搜索自己提交的IP
      search() {
        let ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
        if (this.input_ip == '') {
          this.$message({
            message: '"IP搜索框不能为空"',
            type: 'error'
          })
        } else if (ip.test(this.input_ip)) {
          this.$axios
            .post('home_ip_submits', {
              ip: this.input_ip,
              id: this.user_id,
              pagenumber: 1
            })
            .then(res => {
              if (res.code == 200) {
                res.data.forEach(item => {
                  item.creatTime = unixTimeToDateTime(item.creatTime)
                  if (item.IP_type == 1) {
                    item.IP_type = '单IP'
                  } else {
                    item.IP_type = 'IP段'
                  }
                })

                this.tableData = []
                this.tableData = res.data
                this.total = res.count
              }
            })
        } else {
          this.input_ip = ''
          this.$message({
            message: '"IP格式错误"',
            type: 'error'
          })
        }
      },
      // 删除IP接口
      delete_ip(ip_id) {
        this.$confirm('此操作将永久删除该IP, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$axios
              .post('/home_del_ip', {
                id: ip_id
              })
              .then(res => {
                if (res.code == 201) {
                  this.get_ip_list()
                }
              })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      },
      // 弹窗接口
      sync_ip() {
        this.sync = false
        this.drawer = true
        //如果要上传表格，就获取到企业名称和企业地址
        this.q_info.address = JSON.parse(window.sessionStorage.getItem('info1')).address
        this.q_info.submitter = JSON.parse(window.sessionStorage.getItem('info1')).enterpriseName
        //获取提交表格的信息
        this.ip_file_journal()
      },

      //上传按钮
      submitUpload() {
        this.$refs.upload_xls.submit();
      },
      // 上传成功触发事件
      handleAvatarSuccess(res) {
        if (res.code == 201) {
          this.$message({
            type: 'success',
            message: res.msg
          })
          this.ip_file_journal()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
          this.ip_file_journal()
        }
      },
      //获取提交表格历史信息
      ip_file_journal() {
        this.$axios('/ip_file_journal').then(res => {
          this.info = res.data
        })
      },
      open_click(msg) {
        this.msg = msg
        this.open = true
      }
    }
  }
</script>
<style scoped>
.el-dialog__header{
  text-align: center !important;
}
  .ipSubmit_home {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .card_top {
    display: flex;
    flex-direction: row;
  }

  .search_ip {
    display: flex;
    margin-top: 20px;
  }

  .search_ip .el-input {
    width: 500px;
  }

  .el-input {
    text-align: left;
    width: 40%;
  }

  .el-button {
    margin-left: 10px;
    width: 85px;
  }

  .pagination {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }

  .search_table {
    margin: 40px 0;
  }

  .add_ip {
    margin-top: 20px;
  }

  .select {
    margin-top: 30px;
    text-align: center;
  }

  .input_ip .el-input {
    margin: 10px 0;
    width: 300px;
  }
  .input_ip /deep/ .el-textarea{
    width: 51%;
  }
  .card /deep/ .el-textarea{
    margin: 5px 0;
    vertical-align: middle;
  }
  .flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .flex>.el-radio-group {
    margin: 5px 2px;
  }

  .input_ips .el-input {
    margin: 10px 0;
    width: 143px;
  }
  .input_ips span,
  .input_ip span {
    margin: 0 5px;
  }

  .solt_button {
    margin-left: -20px;
  }

  .solt_button>.el-button {
    border-radius: 5%;
  }

  .upload_excel {
    margin: 20px;
    text-align: center;
  }

  .upload_excel .el-button {
    width: 100px;
  }

  .upload_submit {
    margin: 10px 0 10px 0;
  }

  .file_style {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    color: #666;
  }

  .flex_Dad {
    display: flex;
    border: 1;
    justify-content: center;
  }

  .flex_Dad>div:last-child {
    margin-left: 10px;
  }

  .flex_Dad_img {
    width: 100%;
  }

  .el-table {
    margin-top: 10px;
  }

  .test {
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .radios {
    position: relative;
    margin: 15px;
  }

  .radios>span {
    position: absolute;
    top: 0;
    left: 20%;
  }

  @media screen and (max-width: 1200px) {
    .flex_Dad{
      display:flex;
      border:1;
      flex-direction:column;
      justify-content:center;
      align-items:center;
    }
  }

  @media screen and (max-width: 550px) {
    .flex_Dad {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .flex_Dad div {
      width: 90%;
    }
    .flex_Dad div img {
      width: 100%;
    }
    .input_ip .el-input {
      margin: 10px 0;
      width: 100%;
    }
    .flex {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    .flex>.el-radio {
      margin: 10px;
    }
    .input_ips .el-input {
      margin: 10px 0;
      width: 100%;
    }
    .input_ips span {
      margin: 0 5px;
    }
  }
</style>